@import "src/assets/styles/mixines";

.maintenance {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maintenanceImg {
  width: 144px;
  height: 144px;
  margin-bottom: 16px;
  background-repeat: no-repeat;
  background-size: 144px auto;
}

.maintenanceTitle {
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.72px;
  margin-bottom: 16px;

  @include --tablet {
    font-size: 36px;
  }
}

.maintenanceDesc {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
