@import "src/assets/styles/mixines";

.container {
  display: flex;
  align-items: center;
  // margin: var(--default-margin) 0;
}

.infoIconWrapper {
  height: 22px;
  padding: 0 12px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
  color: var(--accent160);
  background: var(--accent30);
}

.betaIcon {
 margin-right: 2px;
}

.item {
  transition: 0.2s;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--gray60);

  &:hover:not(&.activeItem) {
    text-decoration: underline;
    color: var(--gray60);
  }

  &.activeItem {
    color: var(--brand-primary);
    cursor: initial;
  }

  @include --tablet-s {
    font-size: 16px;
  }
}

.itemLabel {
  color: inherit;
}

.arrowWrapper {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
}
