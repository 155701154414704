.group {
  margin-bottom: 12px;
}

.zipGroup {
  flex: 1;
  max-width: 148px;
}

.row {
  display: flex;

  .group {
    flex: 1;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.accordion {
  margin-bottom: 12px;

  &.active {
    margin-bottom: 28px;
  }
}

.accordionToggle {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  border: none;
  outline: none;
  background: transparent;
  border-bottom: 1px dashed var(--gray60);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: var(--accent120);
  }
}

.toggleIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  transition: 0.1s;
}

.accordionContent {
  max-height: 0;
  transition: 0.3s;
  overflow: hidden;

  &.active {
    padding-top: 12px;
    max-height: 1000px;
  }
}

.accordionMain {
  position: relative;


  .couponInput {

  }

  .couponBtn {
    position: absolute;
    right: 14px;
    top: 0;
    min-height: 100%;
    color: var(--brand-primary);
    text-decoration: underline;
  }
}

.appliedCouponInfo {
  margin-bottom: 28px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

.submitBtn {
  margin-bottom: 16px;
}

.reviews {
  display: flex;
  justify-content: center;
}

.reviewItem {
  svg {
    width: 88px;
  }

  &:not(:last-child) {
    margin-right: 88px;
  }
}

.optionFlag {
  width: 22px;
  height: 16px;
}
