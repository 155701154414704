.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {

}

.loaderWrapper {
  width: 48px;
  height: 48px;
  margin-bottom: 16px;

  svg {
    width: 38px;
    height: 38px;
  }
}

.text {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
