.container {
  position: relative;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--gray10);
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(34, 37, 47, 0.04);
}

.loaderContainer {
  position: absolute;
  right: 8px;
  top: 10px;
  width: 30px;
  height: 30px;
  color: var(--accent120);
}

.header {
  display: flex;
  margin-bottom: 8px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.title {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.product {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.separator {
  margin: 16px 0;
  border-bottom: 1px dashed var(--brand-primary);
}

.price {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px
}
