@import "src/assets/styles/mixines";

.loaderContainer {
  margin: 50px auto 0;
  color: var(--accent120);

  svg {
    width: 40px;
    height: 40px;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 16px;

  @include --tablet-s {
    padding: 0 40px;
    margin-bottom: 20px;
  }
}

.plansList {
  margin-bottom: 32px;
}

.promoBlock {
  margin-bottom: 52px;
}

.reviewList {
  display: none;
  padding: 0 16px;

  @include --tablet-s {
    padding: 0 40px;
  }

  @include --tablet-l {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.reviewItem {
  width: 99px;

  &:not(:last-child) {
    margin-right: 24px;
  }
}
