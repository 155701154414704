.container {
  display: flex;

  &.bordered {
    padding: 5px;
    border: 1px solid var(--gray10);

    &.m {
      border-radius: 8px;

      .item {
        min-height: 28px;
      }
    }

    &.l {
      border-radius: 12px;

      .item {
        min-height: 42px;
        font-size: 16px;
      }
    }

    &.blue {
      background: var(--brand-white);
    }

    &.white {
      background: var(--slate-100);
    }
  }

  &.header {
    padding: 5px;
    background: var(--slate-100);
    border-radius: 6px 6px 0 0;

    &.m {
      .item {
        height: 32px;
      }
    }

    &.l {
      .item {
        height: 42px;
      }
    }
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: transparent;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: 0.2s;
  cursor: pointer;
  min-height: 28px;
  color: inherit;

  &:hover {
    color: var(--brand-onPrimary);
  }

  &.active {
    color: var(--brand-onPrimary);

    &.white {
      background: #fff;

      .itemNote {
        background: var(--accent30);
      }
    }

    &.blue {
      background: var(--accent30);

      .itemNote {
        background: #fff;
      }
    }
  }

  &.m {
    border-radius: 3px;
  }

  &.l {
    border-radius: 10px;
    font-size: 16px;
  }

  &.wide {
    flex: 1;
  }
}

.itemNote {
  margin-left: 16px;
  height: 26px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: var(--accent140);
  background: var(--accent30);
}
