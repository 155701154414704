@import "src/assets/styles/mixines";

.wrapper {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;

  @include --tablet {
    flex-direction: row;
    padding: 135px 40px 120px;
  }

  @include --desktop-s {

  }
}

.leftBlock {
  flex: 1;
  margin-bottom: 32px;

  @include --tablet {
    margin-bottom: 32px;
    margin-right: 24px;
  }

  @include --desktop-s {
    max-width: 444px;
    margin-right: 200px;
    margin-bottom: 0;
  }
}

.logoWrapper {
  width: 138px;
  display: flex;
  transition: 0.2s;
  margin-bottom: 24px;

  svg {
    max-width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  @include --tablet {
    margin-bottom: 64px;
  }
}

.description {
  margin-bottom: 40px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  display: none;

  @include --tablet {
    display: block;
  }
}

.tools {
  // margin-bottom: 64px;
  margin-bottom: 32px;
  display: none;

  @include --tablet {
    display: block;
  }
}

.tool {
  display: flex;
  align-items: center;
  border-radius: 24px 0 0 24px;
  padding: 6px;
  background: linear-gradient(90deg, #F9FAFB 25%, rgba(255, 255, 255, 0.00) 100%);

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.toolIconWrapper {
  width: 32px;
  height: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--accent306);
}

.toolName {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: var(--accent120);
}

.reviews {
  display: none;

  @include --tablet {
    display: flex;
  }
}

.rightBlock {
  flex: 1;
  max-width: 600px;
  margin: 0 auto;

  @include --tablet {
    max-width: initial;
  }
}

.formWrapper {
  position: relative;
  margin-bottom: 46px;
}

.formBackground1 {
  z-index: 4;
  position: absolute;
  left: -20%;
  bottom: 30px;
  width: 357.604px;
  height: 357.604px;
  transform: rotate(-14.515deg);
  flex-shrink: 0;
  border-radius: 300px;
  opacity: 0.5;
  background: radial-gradient(442.25% 71.72% at 49.29% 312.72%, #FFF 0%, #00A3FF 100%);
  filter: blur(100px);
}

.formBackground2 {
  z-index: 4;
  position: absolute;
  right: -20%;
  top: 20px;
  width: 357.604px;
  height: 357.604px;
  transform: rotate(-14.515deg);
  flex-shrink: 0;
  border-radius: 300px;
  opacity: 0.5;
  background: radial-gradient(442.25% 71.72% at 49.29% 312.72%, #FFF 0%, #9FA9FA 100%);
  filter: blur(100px);
}

.formContainer {
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 40px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24), 0 16px 32px -4px rgba(145, 158, 171, 0.24);
}

.title {
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.72px;

  @include --tablet {
    font-size: 36px;
  }
}

.form {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  > div {
    padding: 0;
  }

  form {
    margin-bottom: 24px;
  }
}

.formInitial {

}

.formFooter {
  margin-top: 12px;
  text-align: center;
  color: #637381;
  font-size: 14px;
  font-weight: 400;

  a {
    color: var(--gray160);
    transition: 0.2s;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;

    &:hover {
      color: var(--gray100);
    }
  }
}

.loginBlock {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.loginLink {
  color: var(--accent100);
  transition: 0.2s;

  &:hover {
    color: var(--accent60);
  }
}

.bottomNotice {
  // color: #667085;
  color: var(--gray120);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @include --tablet {
    padding: 0 110px;
  }

  a {
    transition: 0.2s;

    &:hover {
      text-decoration: underline;
      color: var(--gray160);
    }
  }
}

.reviewItem {
  height: 60px;

  &:not(:last-child) {
    margin-right: 40px;
  }
}

.separator {
  display: flex;
  align-items: center;
  margin: 24px 0;
  font-size: 12px;
  color: var(--gray120);
}

.separatorLine {
  height: 1px;
  flex: 1;
  background: var(--gray10);

  &:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 10px;
  }
}

.inputs {
  margin-bottom: 40px;
}

.input {
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.promoBlock {
  // width: 100%;
  padding: 16px;
  border-radius: 16px;
  background: var(--brand-onPrimary);
  box-shadow: 0px 4px 12px 0px rgba(34, 37, 47, 0.12);
  background-position: calc(100% - 29px) 16px;
  background-repeat: no-repeat;
  background-size: 130px auto;

  @include --tablet {
    margin-bottom: 32px;
  }
}

.promoHeader {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #fff;
}

.promoIcon {
  margin-right: 4px;
}

.promoTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
}

.promoDesc {
  padding-left: 4px;
  padding-right: 150px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
}
