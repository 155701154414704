.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
  background: var(--accent10);
}

.infoItem {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-right: 32px;
  }
}

.infoItemIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
}

.infoItemTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.infoItemDesc {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.arrowIconContainer {
  margin-right: 32px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray60);

  svg {
    width: 24px;
    height: 24px;
  }
}

.description {
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  b {
    font-weight: 500;
  }
}
