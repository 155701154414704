@import "src/assets/styles/mixines";

.container {
  margin-bottom: 16px;
}

.tableContainer {
  overflow-x: auto;
  padding: 0 16px 8px;

  @include --tablet-s {
    padding: 0 40px 8px;
  }
}

.tableWrapper {
  min-width: 1080px;
}

.title {
  margin-bottom: 32px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.subheader {
  display: flex;
  align-items: center;

  .subheaderTitle {
    padding-left: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }

  .subheaderDescription {
    padding-left: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: var(--gray100);
  }
}

.table {
  display: flex;

  &.upperTable {
    border: 1px solid var(--gray10);
    border-radius: 24px 24px 0 0;

    .tableCol {
      &:first-child {
        .colHeader {
          border-radius: 24px 0 0 0;
        }
      }

      &:last-child {
        .colHeader {
          border-radius: 0 24px 0 0;
        }
      }
    }
  }

  &.lowerTable {
    border: 1px solid var(--gray10);
    border-top: none;
    border-radius: 0 0 24px 24px;

    .tableCol {
      &:first-child {
        .colFooter {
          border-radius: 0 0 0 24px;
        }
      }

      &:last-child {
        .colFooter {
          border-radius: 0 0 24px 0;
        }
      }
    }
  }
}

.tableCol {
  flex: 1;
  display: flex;
  flex-direction: column;

  &:first-child {
    width: 292px;
    max-width: 292px;
    min-width: 292px;

    .cell {
      padding-left: 20px;
      border-left: 1px solid var(--gray10);
    }
  }

  &:nth-child(2) .colHeader,
  &:nth-child(2) .colFooter,
  &:nth-child(2) .cell {
    background: var(--accent304);
  }

  &:nth-child(3) .colHeader,
  &:nth-child(3) .colFooter,
  &:nth-child(3) .cell {
    background: var(--accent306);
  }

  &:nth-child(4) .colHeader,
  &:nth-child(4) .colFooter,
  &:nth-child(4) .cell {
    background: var(--accent308);
  }

  &:nth-child(5) .colHeader,
  &:nth-child(5) .colFooter,
  &:nth-child(5) .cell {
    background: var(--accent30);
  }

  &:last-child {
    // border-radius: 0 24px 24px 0;
  }

  &.highlighted {
    color: var(--accent100);
    font-weight: 700;
  }
}

.subColumnWrapper {
  &:not(:last-child) {
    .subColumn .cell:last-child {
      border-bottom: none;
    }

    .cell.toggle {
      &:not(.active) {
        border-bottom: none;
      }
    }
  }
}

.subColumn {
  max-height: 0;
  overflow: hidden;
  transition: 0.2s;

  &.active {
    max-height: 10000px;
  }

  &.rowLabels .cell {
    padding-left: 30px;
  }

  .cell:first-child {
    border-top: none;
  }
}

.colHeader {
  padding: 22px 24px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.colFooter {
  padding: 22px 24px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.cell {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px 0 24px;
  border-top: 1px solid var(--gray10);
  font-size: 16px;
  line-height: 19px;

  &:last-child {
    border-bottom: 1px solid var(--gray10);
  }

  &.toggle {
    cursor: pointer;
    border-bottom: 1px solid var(--gray10);
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  b {
    font-weight: 600;
  }
}

.toggleIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  transition: 0.3s;

  &.active {
    transform: rotate(180deg);
  }
}

.planHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.planName {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.planPrice {
  display: flex;
  align-items: flex-end;
  flex: 1;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.priceMain {
  font-size: 24px;
  line-height: 26px;
}

.priceLabel {
  margin: 0 0 2px 4px;
  font-size: 16px;
  line-height: 1;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.btnWrapper {
  margin-top: auto;
  width: 100%;

  .planBtn {
    width: 100%;

    span {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

.infoIconWrapper {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray100);
  transition: 0.2s;

  &:hover {
    color: var(--gray120);
  }
}

.infoIconWrapper {
  margin-left: 4px;
}
