.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input {
  display: none;
}

.box {
  display: flex;
  width: 46px;
  height: 28px;
  border-radius: 16px;
  background: var(--gray30);
  position: relative;
  cursor: pointer;
  transition: 0.2s;

  &.checked {
    background: linear-gradient(90deg, var(--gray100) 0%, var(--gray120) 30%, var(--gray140) 60%);

    .switch {
      transform: translate3d(17px, -50%, 0);
    }
  }
}

.switch {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  transition: 0.1s;
}

.labelLeft {
  margin-right: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: var(--text-black);
}

.labelRight {
  margin-left: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: var(--text-black);
}
