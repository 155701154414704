@import "src/assets/styles/mixines";

.container {
  padding: 16px;
  background: var(--brand-white);
  border: 1px solid var(--gray05);
  border-radius: 16px;
  background-repeat: no-repeat;
  color: var(--brand-primary);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  flex: 1;
  background-position: calc(100% - 48px) 30px;
  background-size: 0;

  @include --tablet-s {
    background-size: 208px auto;
    padding: 32px 290px 32px 32px;
  }
}

.title {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.content {
  font-size: 14px;
  line-height: 22px;
}

.itemsList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.itemIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
