@import "src/assets/styles/mixines";

.card {
  padding: 16px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid var(--gray05);

  @include --tablet-s {
    padding: 24px;
  }

  @include --tablet {
    width: 350px;
  }
}

.title {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.description {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.prices {
  margin-bottom: 16px;
}

.pricesList {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.pricesTitle {
  margin-bottom: 6px;
}

.price {
  position: relative;
  padding-left: 24px;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 26px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 10px;
    top: 50%;
    background: var(--brand-primary);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    transform: translateY(-50%);
  }

  &:not(:last-child) {
    // margin-bottom: 8px;
  }
}
