.container {

}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  border-radius: 6px 6px 0 0;
  background: var(--accent500);
}

.content {
  padding: 16px;
  background: var(--accent700);
  border-radius: 0 0 8px 8px;
  transition: 0.2s;
  // overflow-wrap: break-word;
  word-break: break-word;
  font-size: 14px;
  font-family: "IBM Plex Mono", monospace;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--brand-white);
  max-height: 232px;
  overflow-y: auto;
}
