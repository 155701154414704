.modalTabs {
  margin-bottom: 20px;
}

.modalNotice {
  margin-bottom: 20px;
}

.autoTopUpNotice {
  margin-bottom: 20px;
}

.inputGroup {
  margin-bottom: 20px;
}

.modalBottom {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: var(--gray100);
  text-align: center;

  a {
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      color: var(--gray120);
    }
  }
}
