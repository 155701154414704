@import "src/assets/styles/mixines";

.container {
  @include --tablet {
    display: flex;
  }
}

.contentBlock {
  padding: 16px;
  background: #fff;

  &.leftSide {
    border-radius: 0 0 0 6px;
  }

  &.rightSide {
    flex: 1;
    position: relative;
    border-radius: 0 0 6px 0;
  }

  @include --tablet-s {
    padding: 32px;
  }

  @include --tablet {
    &.leftSide {
      width: 480px;
      min-width: 480px;
      max-width: 480px;
    }

    &.rightSide {
      width: calc(100% - 480px);
      min-width: calc(100% - 480px);
      max-width: calc(100% - 480px);
    }
  }
}

.title {
  margin-bottom: 24px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.form {
}

.formRow {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include --tablet-s {
    display: grid;
    // grid-row-gap: 16px;
    grid-column-gap: 16px;
    // grid-template-columns: 1fr;
    // grid-template-columns: 200px 200px;
    grid-template-columns: 1fr 1fr;
  }

  @include --tablet {
    grid-template-columns: 200px 200px;
  }
}

.inputGroup {
  // width: 200px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .input {
    width: 100%;
  }
}

.backgroundBlock {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 120px;
  border-radius: 0 0 8px 8px;
  background: var(--accent30);
}

.blockHeader {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  div {
    display: flex;
    align-items: center;
  }
}

.blockTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.checkIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.pricingBlock {
  margin-bottom: 24px;
}

.resultContent {
  display: flex;
  flex-direction: column;
}

.resultTabsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;

  .resultTabItem {
    &:hover {
      color: var(--brand-white);
      background: var(--accent60);
    }
  }
}

.builderHeader {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-radius: 6px 6px 0 0;
  background: var(--gray07);
}

.builderContent {
  padding: 20px;
  border-radius: 0 0 8px 8px;
  background: var(--gray160);
  color: var(--blue);
  overflow-wrap: break-word;

  span {
    color: var(--critical100);
  }
}
