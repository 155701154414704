@import "src/assets/styles/mixines";

.spinnerContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--accent140);
}

.plansSection {
  position: relative;
}

.sectionHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;

  @include --tablet-s {
    margin-bottom: 16px;
  }
}

.switcherContainer {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: center;

  @include --tablet-xs {
    padding: 0 28px;
  }

  @include --tablet-s {
    padding: 0 32px;
    width: unset;
  }

  @include --tablet-l {
    padding: 0 24px;
  }
}

.switcher {
  width: 100%;

  @include --tablet-s {
    width: unset;
  }
}

.featuresList {
  display: none;
  justify-content: center;

  @include --tablet-l {
    display: flex;
    margin-bottom: 32px;
  }
}

.featureItem {
  display: flex;
  align-items: center;
  color: var(--text-black);
  font-size: 16px;
  line-height: 26px;

  &:not(:last-child) {
    margin-right: 80px;
  }
}

.featureIconCircle {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  border-radius: 50%;
  background: var(--accent100);
  color: var(--brand-white);
}

.featureIcon {
  width: 12px;
  height: 10px;
}

.payments {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @include --tablet-s {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    margin-bottom: 32px;
  }

  &.margined {
    @include --tablet-s {
      margin-bottom: 64px;
    }
  }
}

.paymentsBlock {
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include --tablet-s {
    &:not(:last-child) {
      margin-right: 32px;
      margin-bottom: 0;
    }
  }

  @include --tablet-l {
    &:not(:last-child) {
      margin-right: 48px;
    }
  }
}

.paymentsTitle {
  margin-right: 12px;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray60);

  @include --phone-m {
    font-size: 16px;
    line-height: 26px;
  }
}

.paymentItem {
  width: 40px;

  &:not(:last-child) {
    margin-right: 12px;
  }

  @include --phone-m {
    width: 46px;
  }

  @include --tablet-l {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.infoWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray100);
  transition: 0.2s;

  &:hover {
    color: var(--gray120);
  }
}

.plansWrapper {
  margin-bottom: 10px;
  overflow-y: auto;
  padding-bottom: 10px;

  @include --tablet-l {
    margin-bottom: 42px;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-height: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.plansRow {
  display: flex;

  @include --desktop-s {
    // padding-left: 24px;
    // padding-right: 24px;
    display: grid;
    grid-row-gap: 16px;
    grid-column-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.planItem {
  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
  }

  @include --tablet-s {
    &:first-child {
      padding-left: 40px;
    }

    &:last-child {
      padding-right: 40px;
    }
  }

  /*&:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }

  @include --tablet-xs {
    &:first-child {
      padding-left: 28px;
    }

    &:last-child {
      padding-right: 28px;
    }
  }

  @include --tablet-s {
    &:first-child {
      padding-left: 32px;
    }

    &:last-child {
      padding-right: 32px;
    }
  }

  @include --tablet-l {
    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }

  @include --desktop-s {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }*/
}

.trial {
  display: none;

  @include --tablet-s {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 40px;
    color: var(--gray60);
  }

  @include --tablet-l {
    margin-bottom: 64px;
  }
}

.trialIconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.trialInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  text-align: center;

  b {
    font-weight: bold;
  }
}

.enterpriseTrigger {
  display: none;

  @include --tablet-l {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }
}

.enterpriseTriggerBtn {
  outline: none;
  border: none;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  font-family: inherit;
  color: var(--accent120);
  text-decoration: underline;
  cursor: pointer;
}

.paddingWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  @include --tablet-xs {
    padding: 0 28px;
  }

  @include --tablet-s {
    padding: 0 32px;
  }

  @include --tablet-l {
    padding: 0 24px;
  }
}

.comparisonTable {
  display: none;

  @include --tablet-l {
    display: block;
    margin-bottom: 80px;
  }
}

.verifInfo {
  margin-bottom: 30px;
}

.featuresBlock {
  margin-bottom: 64px;
}

.intercomTrigger {
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }
}
