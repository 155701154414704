@keyframes spin {
  0% { transform: rotate(0deg); }
  100% {  transform: rotate(359deg); }
}

.spinner {
  animation: spin 1.5s linear infinite;
}

.container {
  display: flex;
  justify-content: center;
  align-content: center;
}
