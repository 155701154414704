.container {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid;

  &.blue {
    color: var(--accent120);
    background: var(--accent30);
    border: none;
  }

  &.red {
    color: var(--critical120);
    background: var(--critical10);
  }

  &.gray {
    color: var(--gray140);
    background: var(--gray05);
    border: none;
  }

  &.green {
    color: var(--positive120);
    background: var(--positive10);
    border: none;
  }
}

.iconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.divider {
  height: 4px;
}

.content {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  a {
    font-weight: 500;
    text-decoration-line: underline;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }
}
