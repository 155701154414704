@import "src/assets/styles/mixines";

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background: var(--accent5);

  @include --tablet-l {
    flex-direction: row;
  }
}

.content {
  flex: 1 1;
  background: var(--accent5);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @include --tablet-l {
    height: 100%;
  }
}

.pageWrapper {
  flex: 1;
}

.pageContainer {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  color: var(--brand-primary);

  &.spaced {
    padding: 0 16px;

    @include --tablet-s {
      padding: 0 40px;
    }
  }
}

.pageContent {
  flex: 1;
  overflow-x: auto;
  // min-width: 800px;
}

.footerWrapper {
  margin-top: 40px;
}

.footer {
  height: 58px;
  // padding: 16px 0 20px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--gray30);
  font-family: inherit;
}

.footerCred {
  color: var(--gray60);
  font-size: 14px;
  line-height: 18px;

  @include --tablet-xs {
    font-size: 16px;
    line-height: 22px;
  }
}
