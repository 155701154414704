.container {
  display: flex;
  font-size: 14px;
  line-height: 14px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    color: var(--gray60);

    .box {
      border: none;
      background: var(--gray10);
      cursor: not-allowed;

      &::after {
        background: var(--gray60);
      }
    }
  }

  &:hover:not(.disabled) {
    .box {
      border: 1px solid var(--brand-primary);

      &.errored {
        border-color: var(--critical100);
      }
    }
  }
}

.input {
  display: none;
}

.box {
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
  border-radius: 2px;
  position: relative;
  border: 1px solid var(--gray60);
  cursor: pointer;
  box-sizing: border-box;
  background: var(--brand-white);
  transition: 0.2s;

  &.disabled {
    cursor: not-allowed;
  }

  &.checked {
    .icon {
      opacity: 1;
    }
  }

  &.errored {
    border-color: var(--critical60);
    background: var(--critical10);
  }

  .icon {
    opacity: 0;
    width: 8px;
    height: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    transition: opacity 0.2s;
    transform: translate3d(-50%, calc(-50% - 1px), 0) rotate(-45deg);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      background: var(--brand-primary);
      border-radius: 1px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100%;
      background: var(--brand-primary);
      border-radius: 1px;
    }
  }
}

.label {
  margin-left: 8px;
  // padding-top: 2px;
}
