@import "src/assets/styles/mixines";

.header {
  display: flex;
  min-height: 60px;
  margin-bottom: 24px;
  margin-top: var(--mobile-header-height);
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray30);

  @include --tablet {
    min-height: 80px;
    margin-bottom: 32px;
  }

  @include --tablet-l {
    margin-top: 0;
  }
}

.itemsContainer {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.item {
  display: flex;
  align-items: center;
  color: var(--brand-primary);
  background: transparent;
  border: none;
  outline: none;
  height: 30px;
  // cursor: pointer;

  &:not(:last-child) {
    margin-right: 40px;
  }

  &:hover {
    // color: var(--brand-onPrimary);
  }
}

.iconWrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  transition: 0.2s;
}

.itemLabel {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  transition: 0.2s;

  @include --tablet {
    font-size: 16px;
  }
}

.balanceLabel {
  display: none;

  @include --tablet {
    display: initial;
  }
}

.loaderContainer {
  margin-left: 16px;
  color: var(--accent120);

  svg {
    width: 20px;
    height: 20px;
  }
}
