@import "src/assets/styles/mixines";

.container {
  height: calc(100vh - 34px);
  display: flex;
  margin-top: -64px;

  @include --tablet-s {
    margin-top: -84px;
    height: calc(100vh - 14px);
  }

  iframe {
    flex: 1;
  }
}
