.modalContent {

}

.main {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.descriptionBlock {
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.description {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.loaderContainer {
  margin-bottom: 16px;
}

.supportLink {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
  transition: 0.2s;

  &:hover {
    color: var(--brand-onPrimary);
    text-decoration: none;
  }
}

.submitBtn {

}

.reviews {
  display: flex;
  justify-content: center;
}

.reviewItem {
  height: 48px;

  &:not(:last-child) {
    margin-right: 88px;
  }
}
