.notice {
  margin-bottom: 24px;
}

.link {
  text-decoration: underline;
  transition: 0.2s;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: var(--accent60);
  }
}
