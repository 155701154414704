.accordion {
  border-radius: 8px;
  background: var(--slate-100);

  .accordionToggle {
    &.active .toggleIconWrapper {
      transform: rotate(180deg);
    }
  }
}

.accordionToggle {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: var(--brand-onPrimary);
  }
}

.toggleIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  transition: 0.1s;
}

.accordionContent {
  max-height: 0;
  transition: max-height 0.3s, opacity 0.3s, padding 0.4s;
  overflow: hidden;
  opacity: 0;
  padding: 0 16px;

  &.active {
    padding: 8px 16px 12px;
    max-height: 1000px;
    opacity: 1;
  }
}
