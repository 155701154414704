@import "src/assets/styles/mixines";

.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--gray05);
  background: var(--brand-white);

  @include --tablet-s {
    padding: 16px 28px;
  }
}

.title {
  margin-bottom: 8px;
  color: var(--gray60);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
