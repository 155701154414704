@import "src/assets/styles/mixines";

.container {
  max-width: 800px;
  // margin-bottom: 24px;

  @include --tablet {
    min-width: 800px;
  }
}

.productsTitle {
  margin-bottom: 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.144px;

  @include --tablet-s {
    font-size: 24px;
    line-height: 32px;
  }
}

.row {
  display: flex;
  flex-direction: column;

  @include --tablet-s {
    flex-direction: row;
  }
}

.productCard {
  flex: 1;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include --tablet-s {
    &:not(:last-child) {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
}

.block {
  margin-bottom: 24px;
}

.supportLink {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  transition: 0.2s;

  &:hover {
    color: var(--brand-onPrimary);
    text-decoration: none;
  }
}
