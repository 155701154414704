@import "src/assets/styles/mixines";

.row {
  width: 100%;
  max-width: 800px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  @include --tablet-s {
    flex-direction: row;
  }

  @include --tablet {
    min-width: 800px;
  }
}

.card {
  flex: 1;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include --tablet-s {
    max-width: calc(50% - 8px);

    &:not(:last-child) {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
}

.cardContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.planInfo {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.planName {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.planStatus {
  margin-left: 8px;
}

.balance {
  display: flex;
  align-items: center;
}

.balanceMain {
  margin-bottom: 11px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.balanceInfo {
  margin-bottom: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.messagesList {
  margin-bottom: 16px;
}

.message {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.messageLink {
  text-decoration: underline;
  font-weight: 700;
  transition: 0.2s;

  &:hover {
    color: var(--brand-onPrimary);
  }
}

.contactLink {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: underline;
  transition: 0.2s;

  &:hover {
    color: var(--gray100);
    text-decoration: underline;
  }
}

.btnsBlock {
  display: flex;
}

.invoiceBtn {
  margin-right: 12px;
}

.topUpBlock {
  display: flex;
  margin-bottom: 11px;
}

.activeTopUpStatus {
  margin-left: 12px;
  height: 22px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
  line-height: 1;
  color: var(--positive120);
  background: var(--positive30);

  &.clickable {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: var(--positive60);
      color: var(--brand-white);
    }
  }
}

.inactiveTopUpStatus {
  margin-left: 12px;
  height: 22px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 1;
  color: var(--accent120);
  background: var(--accent30);

  &.clickable {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: var(--accent60);
      color: var(--brand-white);
    }
  }
}

.rowBlock {
  display: flex;
  align-items: center;
}
