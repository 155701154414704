.status {
  height: 22px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;

  &.active {
    background: var(--emerald200);
    color: var(--emerald700);
  }

  &.trial {
    background: var(--accent30);
    color: var(--accent120);
  }

  &.paused {
    background: var(--warning30);
    color: var(--warning120);
  }

  &.unpaid {
    background: var(--warning30);
    color: var(--warning120);
  }

  &.expired {
    background: var(--critical30);
    color: var(--critical120);
  }

  &.blocked {
    background: var(--critical30);
    color: var(--critical120);
  }

  &.cancelled {
    background: var(--gray30);
    color: var(--gray120);
  }
}
