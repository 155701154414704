.container {
  position: relative;
}

.tip {
  display: none;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 12px;
  padding: 3px;
  border-radius: 4px;
  background: var(--brand-white);

  &.active {
    display: initial;
  }
}
