@import "src/assets/styles/mixines";

.container {
  @include --tablet {
    min-width: 800px;
  }
}

.content {
  border: 1px solid var(--gray07);
  background: #fff;
  border-top: none;
  border-radius: 0 0 6px 6px;
  padding: 16px;

  @include --tablet-s {
    padding: 32px;
  }
}

