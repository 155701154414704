@import "src/assets/styles/mixines";

.form {

}

.formBlock {
  display: flex;
  margin-bottom: 10px;
}

.formLeft {
  display: none;

  @include --tablet-s {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
    height: 100%;
    min-height: 100%;
  }
}

.formNumber {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  background: var(--gray10);
}

.formLine {
  flex: 1;
  width: 1px;
  background: var(--gray10);
}

.formRight {
  flex: 1;
  padding: 3px 0 3px;
}

.targetGroup {
  margin-bottom: 8px;
}

.groupInnerBlock {
  width: 100%;
}

.infoIconWrapper {
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sessionTypeTabs {
  margin-bottom: 8px;
}

.sessionTypeRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  @include --tablet-s {
    flex-direction: row;
    align-items: flex-start;
  }
}

.sessionPeriod {
  display: flex;
  // align-items: center;
  margin-bottom: 12px;

  @include --tablet-s {
    margin-bottom: 0;
  }
}

.periodBlock {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.intervalLabel {
  width: 46px;
  margin-right: 8px;
  line-height: 40px;
}

.timeIntervalSelect {
  width: 180px;
  // margin: 0 8px;
}

.customTimeIntervalBlock {
  margin-top: 8px;
}

.sessionTypeDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.radioWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include --tablet {
    flex-direction: row;
    align-items: center;
  }

  &:not(:last-child) {
    margin-bottom: 24px;

    @include --tablet {
      margin-bottom: 0;
    }
  }
}

.bindIpBlock {
  display: flex;
  align-items: center;

  @include --tablet-s {
    margin-left: 16px;
    height: 40px;
  }

  @include --tablet {
    margin-left: auto;
  }
}

.inputGroupBottomNotice {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px
}

.optionFlag {
  width: 22px;
  height: 16px;
}

.deepTargetHeader {
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
}

.deepTargetResetBtn {
  margin-left: auto;
}

.deepTargetLoaderContainer {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  opacity: 0;
  transition: 0.2s;

  &.active {
    opacity: 1;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.deepTargetBlock {
  display: none;

  &.active {
    padding-top: 8px;
    display: block;
  }
}

.deepTargetSelect {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.customTimeIntervalInput {
  width: 180px;
}
