.container {
  height: calc(100vh - 98px);
  display: flex;

  iframe {
    flex: 1;
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: var(--accent120);
}
