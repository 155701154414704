@import "src/assets/styles/mixines";

.wrapper {
  padding-top: 85px;
  // max-width: 1080px;
  max-width: 1112px;
  margin: 0 auto;

  @include --tablet-s {
    max-width: 1160px;
  }

  @include --tablet-l {
    padding-top: 56px;
  }
}

.header {
  padding: 0 16px;

  @include --tablet-s {
    padding: 0 40px;
  }
}

.title {
  text-align: center;
  margin-bottom: 8px;
  color: var(--text-black);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;

  @include --tablet-s {
    margin-bottom: 16px;
  }

  @include --tablet-l {
    font-size: 36px;
  }
}

.productsList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;

  @include --tablet-xs {
    align-items: center;
  }

  @include --tablet-l {
    flex-direction: row;
    margin-bottom: 36px;
  }
}

.productItem {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @include --tablet-s {
    font-size: 18px;
    line-height: 28px;
  }

  @include --tablet-l {
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
}

.productItemIconWrapper {
  margin-right: 8px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtitle {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
