@import "src/assets/styles/mixines";

.item {
  max-width: 100%;
  width: 100%;
  height: 34px;
  padding: 0 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  // border: 2px solid transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  position: relative;
  color: var(--brand-white);

  &:hover {
    background: var(--accent70);
    color: var(--brand-white);
  }

  @include --tablet-l {
    // max-width: 60px;
    // padding: 0 13px;
  }

  @include --desktop-sa {
    min-height: 40px;
  }

  &.labeled {
    // padding: 0 18px;
    max-width: 208px;
    // min-height: 40px;

    .label {
      max-width: 208px;
      opacity: 1;
    }

    .iconWrapper {
      margin-right: 8px;
      width: 20px;
    }
  }

  &.active {
    // color: var(--brand-accent);
    background: var(--accent70);

    /*&::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 18px;
      right: 0;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--brand-accent);
      z-index: 100;
    }*/

    .iconWrapper,
    .label {
      color: inherit;
    }
  }

  @include --desktop-sa {
    // padding: 0 18px;
    max-width: 208px;
  }
}

.iconWrapper {
  width: 20px;
  min-width: 20px;
  // height: 36px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  @include --tablet-l {
    // width: 40px;
    margin-right: 0;
  }

  @include --desktop-sa {
    margin-right: 8px;
    // width: 20px;
  }

  svg {
    width: 20px;
  }
}

.label {
  padding: 5px 0;
  overflow-x: hidden;
  transition: 0.2s;
  white-space: nowrap;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: -0.2px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  @include --tablet-l {
    max-width: 0;
    opacity: 0;
  }

  @include --desktop-sa {
    // margin-left: 8px;
    max-width: unset;
    opacity: 1;
    letter-spacing: 0;
  }
}
