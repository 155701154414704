@import "src/assets/styles/mixines";

.container {
  .notice {
    max-width: 800px;
    margin-bottom: 24px;
    background-position: calc(100% - 48px) 16px;
    background-size: 0;
    // padding-right: 250px;

    @include --tablet-s {
      padding-right: 250px;
      background-size: 192px auto;
    }
  }
}

.link {
  text-decoration: underline;
  transition: 0.2s;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: var(--accent60);
  }
}

.supportLink {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  transition: 0.2s;

  &:hover {
    color: var(--brand-onPrimary);
    text-decoration: none;
  }
}
