@import "src/assets/styles/mixines";

.desktopWrapper {
  display: none;

  @include --tablet-l {
    display: initial;
  }
}

.desktopSidebar {
  display: flex;
  flex-direction: column;
  width: 100px;
  min-width: 100px;
  height: 100%;
  position: relative;

  @include --desktop-sa {
    width: 240px;
  }
}

.sidebarContent {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100px;
  display: flex;
  flex-direction: column;
  padding: 32px 0 5px;
  transition: 0.2s;
  background: var(--accent120);

  &:hover {
    width: 240px;

    .desktopHeader {
      padding: 0 32px;
    }

    .desktopContent {
      // margin-right: 8px;
      // padding-left: 20px;

      &::-webkit-scrollbar {
        width: 4px; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: var(--gray10); /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--accent60); /* color of the scroll thumb */
        border-radius: 2px; /* roundness of the scroll thumb */
        // border: 3px solid orange;  /* creates padding around scroll thumb */
      }
    }

    .sectionLabel {
      padding: 0 20px;
      text-align: left;
    }
  }

  @include --desktop-sa {
    padding-top: 28px;
    position: unset;
    width: 240px;
  }
}

.desktopHeader {
  margin-bottom: 28px;
  padding: 0 32px;
}

.desktopLogoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--accent60);
  color: var(--brand-white);

  @include --desktop-sa {
    padding-bottom: 19px;
  }

  &:hover {
    color: var(--brand-white);
  }
}

.desktopLogo {
  width: 60px;
  height: 12px;
  min-width: 60px;
  min-height: 12px;

  @include --desktop-sa {
    width: 152px;
    height: 32px;
    min-width: 152px;
    min-height: 32px;
  }
}

.desktopContent {
  flex: 1;
  overflow-y: auto;
  // padding: 0 20px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;

  @include --desktop-sa {
    /*padding-left: 20px;
    padding-right: 10px;
    margin-right: 8px;*/

    &::-webkit-scrollbar {
      width: 4px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: var(--gray10); /* color of the tracking area */
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent60); /* color of the scroll thumb */
      border-radius: 2px; /* roundness of the scroll thumb */
      // border: 3px solid orange;  /* creates padding around scroll thumb */
    }
  }
}

.mobileWrapper {
  // height: var(--mobile-header-height);

  @include --tablet-l {
    // height: 0;
    display: none;
  }
}

.mobileHeader {
  height: var(--mobile-header-height);
  width: 100%;
  position: fixed;
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: flex-start;
  background: var(--accent120);
}

.mobileLogoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  flex: 1;
  margin-right: 70px;
  color: var(--brand-white);
  transition: 0.2s;

  &:hover {
    color: var(--brand-white);
  }
}

.mobileLogoIcon {
  width: 78px;
  height: 16px;
}

.hamburger {
  width: 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;

  &.active {
    .hamburgerInner {
      transition-delay: .12s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: rotate(
          45deg
      );
    }

    .hamburgerInner::before {
      top: 0;
      transition: top 75ms ease,opacity 75ms ease .12s;
      opacity: 0;
    }

    .hamburgerInner::after {
      bottom: 0;
      transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
      transform: rotate(
          -90deg
      );
    }
  }
}

.hamburgerBox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 30px;
}

.hamburgerInner,
.hamburgerInner::before,
.hamburgerInner::after {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #fff;
}

.hamburgerInner {
  top: 50%;
  display: block;
  margin-top: -2px;
  transition-timing-function: cubic-bezier(.55,.055,.675,.19);
  transition-duration: 75ms;
}

.hamburgerInner::before {
  content: '';
  top: -7px;
  transition: top 75ms ease .12s,opacity 75ms ease;
}

.hamburgerInner::after {
  content: '';
  transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
  bottom: -7px;
}

.mobileMenu {
  position: fixed;
  z-index: 100;
  top: var(--mobile-header-height);
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 414px;
  height: calc(100% - var(--mobile-header-height));
  // padding: 4px 10px;
  transition: 0.2s;
  background: var(--accent120);
  transform: translate3d(-100%, 0, 0);
  border-top: 2px solid var(--accent10);

  &.active {
    max-height: calc(100vh - var(--mobile-header-height));
    transform: translate3d(0, 0, 0);
  }

  @include --tablet-xs {
    max-width: 240px;
  }
}

.mobileMenuWrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 20px;

  &::-webkit-scrollbar {
    width: 4px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: var(--gray10); /* color of the tracking area */
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--accent60); /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    // border: 3px solid orange;  /* creates padding around scroll thumb */
  }
}

.mobileMenuContent {
  display: flex;
  padding: 20px 0 0;
  flex-direction: column;
  min-height: 100%;
}


.mobileMenuOverlay {
  //display: none;
  //position: absolute;
  //left: 0;
  //top: 0;
  //bottom: 0;
  //right: 0;


  background-color: transparent;
  z-index: -1;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: background-color .4s;

  &.active {
    z-index: 80;
    background-color: rgba(0,0,0,.3);
  }
}

.section {
  &:not(:last-child) {
    margin-bottom: 28px;
  }

  &:last-child {
    margin-top: auto;
    margin-bottom: 0;
    padding-bottom: 20px;
  }
}

.sectionLabel {
  padding: 0;
  margin-bottom: 9px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--accent60);
  transition: 0.2s;
  text-overflow: ellipsis;
  overflow: hidden;

  @include --tablet {
    text-align: center;
  }

  @include --desktop-sa {
    padding: 0 20px;
    text-align: left;
  }
}

.navItem {
  color: var(--brand-white);

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &.inner {
    margin-left: 20px;

    @include --tablet-l {
      margin-left: 0;

      &.margined {
        margin-left: 20px;
      }
    }

    @include --desktop-sa {
      margin-left: 20px;
    }
  }
}

.navItemsWrapper {
  padding-top: 4px;
}
