.message {
  position: absolute;
  left: 0;
  bottom: -4px;
  transform: translateY(100%);
  font-size: 12px;
  line-height: 18px;
  color: var(--critical100);
}

.error {
  color: var(--critical100);
}

.warning {
  color: var(--warning100);
}
