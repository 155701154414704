.section {
  max-width: 1080px;
}

.content {
  border: 1px solid var(--gray07);
  background: #fff;
  border-top: none;
  border-radius: 0 0 6px 6px;
}
