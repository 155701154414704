.container {
  display: block;
  position: relative;
  color: var(--brand-primary);

  &.errored {
    color: var(--critical100);
  }

  &.label_m {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    .labelTop {
      margin-bottom: 8px;
    }

    .labelLeft {
      line-height: 36px;
    }
  }

  &.label_l {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    .labelTop {
      margin-bottom: 8px;
    }

    .labelLeft {
      line-height: 50px;
    }
  }
}

.content {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.childrenWrapper {
  position: relative;
  flex: 1;
  display: flex;
  max-width: 100%;

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }
}

.labelTop {
  display: flex;
  align-items: center;

  &.bold {
    font-weight: 700;
  }
}

.labelLeft {
  margin-right: 24px;
  white-space: nowrap;

  &.bold {
    font-weight: 700;
  }
}

.infoIconWrapper {
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomNotice {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--gray100);
}
