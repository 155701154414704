@import "src/assets/styles/mixines";

.container {
  width: 100%;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  &.margined {
    margin-right: 8px;
  }
}

.error,
.warning {
  position: absolute;
  left: 0;
  bottom: -4px;
  transform: translateY(100%);
}

.error {
  color: var(--critical100);
}

.warning {
  color: var(--warning100);
}

.optionValue {
  margin-right: 8px;
  font-size: 14px;

  @include --tablet-s {
    font-size: 16px;
  }
}

.optionNotice {
  min-height: 22px;
  padding: 4px 12px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: var(--accent120);
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  color: var(--brand-white);

  @include --tablet-s {
    font-size: 12px;
    line-height: 18px;
  }

  &.singleValueNotice {
    display: none;

    @include --tablet-s {
      display: initial;
    }
  }
}
